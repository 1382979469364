






























import { defineComponent, ref, PropType } from '@vue/composition-api';

interface Faq {
  question: string;
  answer: string;
}

export default defineComponent({
  name: 'FCFaqCard',
  props: {
    faq: {
      type: Object as PropType<Faq>,
      required: true,
    },
    codeId: {
      type: String,
      required: true,
    },
  },
  setup() {
    const isActive = ref(false);
    const faqAnswer = ref<HTMLElement>();

    function toggleCard() {
      isActive.value = !isActive.value;
      if (faqAnswer?.value?.style.maxHeight) {
        faqAnswer.value.style.maxHeight = '';
      } else if (faqAnswer.value) {
        faqAnswer.value.style.maxHeight = `${faqAnswer.value.scrollHeight * 2}px`;
      }
    }

    return {
      isActive,
      faqAnswer,
      toggleCard,
    };
  },
});
